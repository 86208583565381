import React from 'react';
import styled from 'styled-components';

import Section from './Section';

const FirstViewportWrapper = styled(Section)<{
  withRightPadding: boolean;
  padding: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: calc(100vh - 70px - 5rem * 2);
  padding-right: ${(props) => (props.withRightPadding ? `` : 0)};
  ${(props) => props.padding && `padding: ${props.padding}`};
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    height: auto;
    padding: 5rem 2rem;
  }
`;

const LeftSideContent = styled.div`
  flex: 1 1 30%;
`;

const RightImage = styled.img<{ width: string; imagePadding?: string }>`
  max-height: 100%;
  padding-left: 1rem;
  ${({ imagePadding }) => imagePadding && `padding: ${imagePadding};`}
  width: ${(props) => props.width && props.width};
  @media (max-width: 1300px) {
    ${(props) => props.width && `width: auto`};
  }
  @media (max-width: 1200px) {
    max-height: auto;
    width: 100%;
    margin-top: 2rem;
    padding-left: 0;
  }
`;
interface ViewportProps {
  img: string;
  withRightPadding?: boolean;
  imgWidth?: string;
  padding?: string;
  imagePadding?: string;
}
const FirstViewport: React.FunctionComponent<ViewportProps> = ({
  children,
  img,
  withRightPadding,
  imgWidth,
  padding,
  imagePadding,
}) => (
  <FirstViewportWrapper padding={padding} withRightPadding={withRightPadding}>
    <LeftSideContent>{children}</LeftSideContent>
    <RightImage
      imagePadding={imagePadding}
      width={imgWidth}
      src={img}
      alt="application screnshot"
    />
  </FirstViewportWrapper>
);

export default FirstViewport;
